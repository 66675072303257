<template>
  <div>
    <c-table
      ref="grid"
      title="항목별 결과 목록"
      :merge="gridHeaderInfo.merge"
      :columns="gridHeaderInfo.cols"
      :data="gridData"
      :gridHeightAuto="true"
      :isTop="false"
      :filtering="false"
      :columnSetting="false"
      :usePaging="false"
      :hideBottom="true"
      @linkClick="openScenario"
    >
    </c-table>
    <c-dialog :param="popupOptions"></c-dialog>
  </div>
</template>

<script>
export default {
  name: 'riskTargetProcess',
  props: {
    assessPlan: {
      type: Object,
      default: () => ({
        ramRiskAssessmentPlanId: '',  // 평가 일련번호
        plantCd: '',  // 사업장 코드
        ramTechniqueCd: '',  // 평가기법 코드_HAZOP/KRAS/JSA/CHECKLIST
        ramAssessTypeCd: '',  // 평가구분_최초/정기/수시
        assessmentName: '',  // 평가명
        assessmentStartDate: '',  // 평가시작일
        assessmentEndDate: '',  // 평가종료일
        assessmentPeriod: [],
        assessmentYear: '',  // 평가년도
        ramStepCd: '',  // 평가 진행단계 코드
        assessmentManageDeptCd: '',  // 평가주관 부서 코드
        assessmentManageUserId: '',  // 평가주관 담당자 ID
        ramMatrixId: null,  // 위험메트릭스 번호
        sopMocId: '',  // MOC 일련번호
        remark: '',  // 상세내용
        workArea: '',  // 작업지역
        regDtStr: '',
        regUserId: '',  // 작성자 ID
        regUserName: '',  // 작성자 ID
        reviewUserId: '',  // 검토자 ID
        reviewDate: '',  // 검토일
        approvalUserId: '',  // 승인자 ID
        approvalDate: '',  // 승인일
        vendorCd: '',
        sopConstructionId: '',
        chgUserId: '',  // 수정자 ID
        teams: [], // 평가팀 목록
        occasions: [], // 수시 변경/추가 내역 목록
        conferences: [], // 회의 목록
        relatedDocuments: [], // 관련자료 목록
        processes: [], // 대상대상공정 목록
      }),
    },
    disabled: {
      type: Boolean,
      default: false,
    }
  },
  data() {
    return {
      editable: true,
      popupOptions: {
        target: null,
        title: '',
        visible: false,
        isFull: true,
        width: '100%',
        param: {},
        closeCallback: null,
      },
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  computed: {
    gridData() {
      let items = [];
      if (this.assessPlan.processes && this.assessPlan.processes.length > 0) {
        this.$_.forEach(this.assessPlan.processes, process => {
          switch(this.assessPlan.ramTechniqueCd) {
            case 'RT00000001': // HAZOP
            case 'RT00000005': // K-PSR
              items = this.$_.concat(items, process.drawNodes)
              break;
            case 'RT00000010': // Check-list
              items = this.$_.concat(items, this.$_.uniqBy(process.targets, ['processCd', 'checkItemName']))
              break;
            case 'RT00000015': // JSA
              items = this.$_.concat(items, this.$_.uniqBy(process.sops, ['processCd', 'jobName']))
              break;
            case 'RT00000020': // KRAS
              items = this.$_.concat(items, this.$_.uniqBy(process.scenarios, ['processCd']))
              break;
            case 'RT00000025': // 4M
              items = this.$_.concat(items, this.$_.uniqBy(process.fmScenarios, ['processCd']))
              break;
            case 'RT00000030': // CHARM
              items = this.$_.concat(items, this.$_.uniqBy(process.charmResultScenarios, ['processCd']))
              break;
            case 'RT00000035': // 3단계 판단법
              items = this.$_.concat(items, this.$_.uniqBy(process.thirdScenarios, ['processCd']))
              break;
            default:
              // 
              break;
          }
        })
      }
      return items;
    },
    gridHeaderInfo() {
      let headerInfo = {
        cols: [],
        merge: [],
      }
      switch(this.assessPlan.ramTechniqueCd) {
        case 'RT00000001': // HAZOP
          headerInfo.cols = [
            {
              name: 'processName',
              field: 'processName',
              label: '대상공정',
              align: 'left',
              style: '100px',
              sortable: false,
            },
            {
              name: 'diagramTitle',
              field: 'diagramTitle',
              label: '도면',
              align: 'left',
              style: '100px',
              sortable: false,
            },
            {
              name: 'nodeTitle',
              field: 'nodeTitle',
              label: '노드',
              align: 'left',
              style: '100px',
              sortable: false,
              type: 'link'
            },
          ]
          headerInfo.merge = [
            { index: 0, colName: 'processCd' },
            { index: 1, colName: 'groupPsiDiagramId' },
          ]
          break;
        case 'RT00000025': // 4M
        case 'RT00000030': // CHARM
          headerInfo.cols = [
            {
              name: 'processName',
              field: 'processName',
              label: '대상공정',
              align: 'left',
              style: '100px',
              sortable: false,
              type: 'link'
            },
          ]
          break;
        default:
          // 
          break;
      }
      return headerInfo;
    }
  },
  methods: {
    init() {
      // role setting
      this.editable = this.$route.meta.editable;
      // url setting
      // code setting
      // list setting
    },
    openScenario(row) {
      this.popupOptions.title = '시나리오 상세'; // 시나리오 상세
      this.popupOptions.param = this.$_.extend(row, { 
        disabled: this.disabled, 
        ramMatrixId: this.assessPlan.ramMatrixId 
      });
      switch(this.assessPlan.ramTechniqueCd) {
        case 'RT00000001': // HAZOP
          this.popupOptions.target = () => import(`${'@/pages/ram/hazop/hazopScenario.vue'}`);
          break;
        case 'RT00000025': // 4M
          this.popupOptions.target = () => import(`${'@/pages/ram/fm/fmScenario.vue'}`);
          break;
        case 'RT00000030': // CHARM
          this.popupOptions.target = () => import(`${'@/pages/ram/charm/charmScenario.vue'}`);
          break;
        default:
          // 
          break;
      }
      
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closePopup;
    },
    closePopup() {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
    },
  }
};
</script>
